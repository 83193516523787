<template>
    <section class="s-newsletter">
        <div class="s-newsletter-text">
            <h3 class="f-st">Newsletter</h3>
            <h2 class="f-hm"><em>Subscribe</em> to get<br>the latest updates</h2>
        </div>
        <div class="s-newsletter-form">
            <form  v-on:submit="newsletter()">
                <div class="s-newsletter-column">
                    <p class="f-pr"> Type <strong>Customer</strong> </p>
                    <ul class="s-newsletter-dropdown">
                        <li>Customer</li>
                        <li>Customer wholesale</li>
                        <li>Agent</li>
                    </ul>
                </div>
                <div class="s-newsletter-column"> <label class="f-fl" for="newsletter">E-mail address</label>
                    <input class="f-ft" id="newsletter" type="email" v-model="email" placeholder="Email address">
                    <input class="f-ft hidden" id="type"  type="text" value="Customer"> </div>
                <div class="s-newsletter-column">
                    <button class="f-bp" id="btn-newsletter" type="submit">
                        <svg class="f-bp-arrow" viewBox="0 0 6 10">
                            <path
                                d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                        </svg>
                        <i class="f-bp-label">Subscribe</i>
                        <svg class="f-bp-arrow" viewBox="0 0 6 10">
                            <path
                                d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
        <div id="showSnackbar">Thank you for Subscribing</div>
    </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            email:''
        }
    },
    methods:{
        newsletter(e){
            event.preventDefault()
            document.getElementById("btn-newsletter").disabled = true;
            document.querySelector('#btn-newsletter').innerHTML = 'Submitting....';
            axios
                .post(
                    this.$store.state.api + "newsletter/_doc",
                    {email:this.email,event: this.$store.state.event,createdAt:moment().format()}
                ).then(response => {
                    var x = document.getElementById("showSnackbar");
                    x.className = "showSnack";
                    axios.post(this.$store.state.api + "send_email", {
                        email: this.email,
                        subject: "Thankyou for Subscribing",
                        bcc: [
                            "arwashahid052@gmail.com",
                        ],
                        from: "info@virtualeye.online",
                        template_id: "d-84d6f9fe49c44926ba30ee7e09a586c5"
                    });
                    setTimeout(function(){ 
                        x.className = x.className.replace("showSnack", ""); 
                    }, 3000);
                    this.email = ''
                    document.querySelector('#btn-newsletter').innerHTML = 'Subscribe >';
                })
        },
    }
}
</script>

<style scoped>
.s-newsletter{
    z-index: 10;
}

#showSnackbar {
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: -10%;
    font-size: 17px;
    transition: all 500ms;
    transform: translate(0,0);
    opacity: 1;
}

#removeSnackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 10px;
    font-size: 17px;
}


.showSnack {
   transform: translateY(-100px) !important;
   opacity: 1 !important;
}
</style>